@import "../../../../../styles/variables.scss";

.mlb-stats {
    &--container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 85%;
        margin: 0 auto;
    }

    &--widget {
        background-color: $grey;
        margin-bottom: 1%;
        border-radius: 10px;
        width: 80%;
        padding: 3%;
    }
}

@media (max-width: 768px) {
    .mlb-stats--container {
      flex-direction: column; // Stack the items vertically on small screens
    }
  }