.controls {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    width: 24px;
    height: 24px;
  }
  p {
    width: 100%;
    text-align: center;
  }
}
