@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

nav {
  background-color: $black;
  margin-bottom: 1%;

  .navbar-container {
    max-width: rem(1080px);
    margin: 0 auto;
    padding: 1%;
    display: flex;
    align-items: center;
    .logo {
      margin-right: rem(125px);
      svg {
        width: rem(90px);
        height: rem(30px);
      }
    }
  }
  
  .menu-icon {
    display: none;
  }

  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media ($maxw-bp-large-mobile) {
    .navbar {
      display: none;
    }
  }
  @media only screen and (max-width: $bp-large-mobile) {
    .navbar {
      display: none;
    }
  }

  .subnav-container {
    &--nba {
      background-color: $grey;
      height: rem(50px);
      
      .schedule-widget {
        position: absolute;
        z-index: 1;
        padding: rem(8px) 0;
        margin-bottom: rem(5px);
        margin-left: 17%;
      }
    }

    &--mlb {
      @media ($maxw-bp-tablet) {
        justify-content: space-between;
      }
      @media ($maxw-bp-large-mobile) {
        display: none;
      }
      @media only screen and (max-width: $bp-large-mobile) {
        display: none;
      }
    }
  }
}
