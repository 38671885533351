@import "../../../../../styles/variables.scss";

.mlb-playoffs {
    &--container {
        display: block;
        margin: 0 7% 0;
        height: 100vh;
        width: 88%;
    }

    &--widget {
        background-color: $grey;
        border-radius: 10px;
        padding: 10%;
    }
}

@media (max-width: 768px) {
    .container {
      flex-direction: column; // Stack the items vertically on small screens
    }
  }