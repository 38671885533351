@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
.videos-list {
  display: flex;
  justify-content: center;
  align-items: center;
  @media ($maxw-bp-md-desktop) {
    position: absolute;
    bottom: rem(75px);
    right: 0;
  }
  @media ($maxw-bp-tablet) {
    position: static;
  }
  @media ($maxw-bp-large-mobile) {
    flex-wrap: wrap;
  }
}
.videos {
  width: rem(162px);
  height: rem(142px);
  border: 1px solid #4d4949;
  position: relative;
  margin: rem(2px) rem(5px) 0;
  border-radius: 10px;
  @media ($maxw-bp-md-desktop) {
    width: rem(90.47px);
    height: rem(79px);
  }
  @media ($maxw-bp-large-mobile) {
    width: rem(90px);
    height: rem(90px);
    border-radius: rem(10px);
    margin: 0 rem(20px) rem(10px);
    .videosDiv > div {
      border-radius: rem(10px);
    }
  }
  &.mine {
    position: relative;
    &:hover .bottom,
    &:active .bottom {
      opacity: 1;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .videosDiv {
    height: 100%;
    & > div {
      border-radius: 10px;
    }
  }
  .bottom {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
  }
}
