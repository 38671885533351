@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/media-queries.scss";

.score-card {
  display: flex;
  border-bottom: 1px solid $border-light-grey;
  padding: rem(15px) rem(6px) rem(15px) rem(6px);
  font-weight: 700;
  .team-name {
    font-size: rem(14px);
  }
  .grid-item {
    display: grid;
    justify-content: center;
    align-content: center;
    text-align: center;

    .game-stats {
      margin-top: rem(4px);
      .score {
        font-size: rem(22px);
        display: flex;
        padding-top: rem(4px);
        div:nth-of-type(1) {
          padding-right: rem(10px);
          border-right: 1px solid $border-light-grey;
        }
        div:nth-of-type(2) {
          padding-left: rem(10px);
        }
      }
      .remaining-time {
        padding-top: rem(4px);
      }
    }
    &.logo {
      width: rem(90px);
    }
    &.stat {
      width: rem(100px);
      .status {
        font-size: rem(14px);
      }
    }
  }
  img {
    margin-left: auto;
    margin-right: auto;
    width: rem(38px);
    margin-bottom: rem(6px);
  }
}
