@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";
.live-like-tabs {
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: flex-end;
    list-style-type: none;
  }
  .tab-item {
    padding: rem(5px);
    cursor: pointer;
    span {
      font-size: rem(12px);
      line-height: rem(14px);
      @include nab-font(Roboto-Bold);
      text-transform: uppercase;
      color: $white;
    }
    &.active span {
      border-bottom: 2px solid $light-blue;
    }
    &::marker {
      z-index: -5;
    }
  }
  .live-like-floater {
    z-index: 1;
    position: absolute;
    left: 0;
    right: rem(4px);
    top: 100%;
    margin-top: rem(6px);
    height: rem(516px);
    > div {
      color: $white;
    }

    @media ($maxw-bp-sm-desktop) {
      height: rem(426px);
    }
    @media ($bp-md-desktop) {
      height: rem(456px);
    }
    @media ($maxw-bp-tablet) {
      height: rem(296px);
    }
  }
}
