@import "../../../../../styles/variables.scss";

.mlb-schedule {
    &--container {
        display: block;
        height: 100vh;
        margin: 0 5% 2%;
    }

    &--widget {
        background-color: $grey;
        border-radius: 10px;
        padding: 2%;
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column; // Stack the items vertically on small screens
    }
}