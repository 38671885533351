@import "./../../../../../styles/variables.scss";
@import "./../../../../../styles/mixins.scss";
@import "./../../../../../styles/media-queries.scss";

.mlb-watch {
  &--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: rem(1080px);
    position: relative;
    margin: 0 auto 2%;
  }

  &--selector {
    background-color: $black;
    display: inline-block;
    padding: 1%;
    border-radius: 10px;
    margin-bottom: 1%;
  }

  &--selector-item {
    cursor: pointer;
    color: $white;
    font-size: rem(18px);
    line-height: rem(21px);
    text-transform: uppercase;
    padding: rem(2px) rem(5px);
    margin: rem(4px) rem(15px);
    @include nab-font(Roboto-Bold);
    &-active {
      cursor: pointer;
      background: $transparent;
      border: 1px solid $white;
      border-radius: rem(6px);
      font-size: rem(18px);
      line-height: rem(21px);
      color: $white;
      text-decoration: none;
      text-transform: uppercase;
      padding: rem(2px) rem(5px);
      margin: rem(4px) rem(15px);
      @include nab-font(Roboto-Bold);
      @media ($maxw-bp-sm-desktop) {
        font-size: rem(14px);
        line-height: rem(16px);
      }
    }
    @media ($maxw-bp-sm-desktop) {
      font-size: rem(14px);
      line-height: rem(16px);
    }
  }

  &--widget {
    align-self: center;
    width: 100%;

    &-container {
      background-color: $grey;
      border-radius: 10px;
      padding: 3%;
    }
  }
}
