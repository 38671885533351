@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";

.navbar-item {
    cursor: pointer;
    font-size: rem(18px);
    line-height: rem(21px);
    font-weight: 400;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    padding: rem(7px) rem(15px);
    margin-right: rem(8px);
    @include nab-font(Roboto-Bold);
    &--active {
        cursor: pointer;
        background: $light-blue;
        border: 1px solid $white;
        border-radius: rem(6px);
        font-size: rem(18px);
        line-height: rem(21px);
        font-weight: 400;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        padding: rem(7px) rem(15px);
        margin-right: rem(8px);
        @include nab-font(Roboto-Bold);
        @media ($maxw-bp-sm-desktop) {
            font-size: rem(14px);
            line-height: rem(16px);
        }
    }
    @media ($maxw-bp-sm-desktop) {
        font-size: rem(14px);
        line-height: rem(16px);
    }
}

.drop-down-menu {
    color: $white;
    background: $grey;
    border-radius: 12px;
    flex-direction: column;
    position: absolute;
    width: 8%;
    top: 7%;
    z-index: 10;
    li {
        cursor: pointer;
        flex-direction: row;
        padding: 6%;
        margin: 3%;
        border-radius: 12px;
    }
    li:hover {
        background-color: $light-blue;
        border: 2px solid $white;
        border-radius: 12px;
    }
}