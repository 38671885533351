@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

.leaderboard {
  height: rem(516px);
  padding-top: rem(12px);
  border-radius: 4px;
  overflow-y: auto;
  color: $white;
  font-size: rem(12px);
  font-weight: 700;
  @media ($maxw-bp-md-desktop) {
    height: rem(446px);
  }
  @media ($maxw-bp-sm-desktop) {
    height: rem(386px);
  }
  @media ($maxw-bp-tablet) {
    height: rem(306px);
  }
  @media only screen and (max-width: $bp-large-mobile) {
    height: rem(306px);
  }

  .header,
  .entry {
    border-radius: 4px;
  }
  .header {
    background-color: $dark-blue;
    height: rem(35px);
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 1fr;
    div {
      margin-right: auto;
      margin-left: rem(24px);
      &:nth-of-type(2) {
        margin-left: rem(16px);
      }
      &:last-of-type {
        margin-left: 0;
      }
    }
  }
  .entry {
    display: grid;
    opacity: 0.9;
    background-color: #343435;
    align-items: center;
    grid-template-columns: 1fr 3fr 1fr;
    height: rem(26px);
    margin-top: rem(8px);
    margin-bottom: rem(8px);
    &.even {
      background-color: #666667;
    }
    div {
      margin-right: auto;
      margin-left: rem(24px);
      &:last-of-type {
        margin-left: rem(2px);
      }
    }
  }
}

.mobile {
  .leaderboard {
    padding: 0 rem(20px) 0 rem(20px);
    .header {
      background-color: #000;
      &:nth-of-type(2) {
        margin-left: rem(24px);
      }
    }
  }
}
