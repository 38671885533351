@import "./styles/mixins.scss";
@import "./styles/media-queries.scss";
@import "./styles/variables.scss";

.app-background {
  background: $black;
  background-size: cover;
  background-repeat: repeat;
  padding-bottom: 0.5%;
  height: 100%;
  @media ($maxw-bp-large-mobile) {
    background: none;
  }
  @media only screen and (max-width: $bp-large-mobile) {
    background: none;
  }
}
