@import "../../../../../styles/variables.scss";

.mlb-standings {
    &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 2%;
    }

    &--widget {
        background-color: $grey;
        border-radius: 10px;
        width: 75%;
        padding: 3%;
    }
}

@media (max-width: 768px) {
    .container {
      flex-direction: column; // Stack the items vertically on small screens
    }
  }