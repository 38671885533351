@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";

.video-container {
  background-color: black;
  padding: rem(10px);
  width: 100%;
  border-radius: rem(10px);
  margin-bottom: 4%;

  @media only screen and (max-width: $bp-large-mobile) {
    margin: 0;
    &--margin {
      margin: 0;
      border-radius: 0;
      padding: 0;
      background-color: $black;
    }
  }

  @media ($maxw-bp-large-mobile) {
    margin: 0;
    &--margin {
      margin: 0;
      border-radius: 0;
      padding: 0;
      background-color: $background-grey;
      @media ($darkColorScheme) {
        background-color: $black;
      }
    }
  }
  .schedule-widget-app {
    border-radius: 10px;
    padding-top: rem(4px);
    margin: 0 auto;
    position: relative;
    margin-bottom: rem(8px);
    width: 100%;
    @media only screen and (max-width: $bp-large-mobile) {
      padding-top: 0;
      margin-top: rem(-6px);
    }
  }
  .widget-name {
    @include nab-font(Roboto-Bold);
    position: relative;
    cursor: pointer;
    border-radius: rem(10px);
    background-color: $dark-blue;
    color: $white;
    width: 100%;
    height: rem(36px);
    padding: 0;
    display: flex;
    align-items: center;
    font-size: rem(12px);
    font-weight: 700;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .chevron {
      position: static;
      margin-left: rem(7px);
      margin-top: rem(3px);
    }
  }
  .schedule-list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-blue;
    &.open {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: $dark-blue;
      border-radius: rem(10px);
      padding: 0;
    }
    li {
      list-style: none;
      font-size: rem(12px);
      @include nab-font(Roboto-Bold);
      cursor: pointer;
      border-radius: rem(10px);
      color: $white;
      width: 100%;
      text-align: center;
      padding: rem(10px);

      @media ($maxw-bp-large-mobile) {
        &.active span {
          border-bottom: 2px solid $light-blue;
        }
      }
      @media only screen and (max-width: $bp-large-mobile) {
        &.active span {
          border-bottom: 2px solid $light-blue;
        }
      }
    }
  }
  @media ($maxw-bp-large-mobile) {
    padding: 0 0 rem(10px);
  }
  @media only screen and (max-width: $bp-large-mobile) {
    padding: 0 0 rem(10px);
  }
}

  iframe {
    width: 100%;
  }
  .isDesktop {
    display: block;
    @media ($maxw-bp-large-mobile) {
      display: none;
    }
  }
  .isMobile {
    display: none;
    @media ($maxw-bp-large-mobile) {
      display: block;
    }
  }
  .hide {
    display: none;
  }