@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
.parent {
  width: 85%;
  overflow: hidden;
  margin: auto;
  padding: 1rem;
}
.slider-container {
  /* if you want to have a scrollbar, you can add overflow here */
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 2rem;
  padding: rem(3px);
  scroll-behavior: smooth;
}
.slider-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  @media ($maxw-bp-md-desktop) {
    max-width: rem(615px);
  }
}
.btn {
  position: absolute;
  box-shadow: 0 0 rem(6.1px) rem(0.9px) grey;
  padding: rem(5px);
  cursor: pointer;
  width: rem(20px);
  height: rem(20px);
  background: grey;
  text-align: center;
  color: white;
  border-radius: 50%;
}
.prev {
  left: 1rem;
}
.next {
  right: 1rem;
}
.disable {
  opacity: 0.5;
  pointer-events: none;
}
.child {
  padding: rem(5px) rem(20px);
  background: #59eb97;
  text-align: center;
  width: 100%;
  margin-right: rem(10px);
  border-radius: rem(21px);
}
