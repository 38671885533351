@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";

livelike-widgets {
  height: rem(516px);
  overflow-y: auto;
  @media ($maxw-bp-md-desktop) {
    height: rem(446px);
  }
  @media ($maxw-bp-sm-desktop) {
    height: rem(386px);
  }
  @media ($maxw-bp-tablet) {
    height: rem(306px);
  }
}

livelike-text-poll,
livelike-text-quiz,
livelike-text-prediction,
livelike-text-prediction-follow-up {
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.78);
  display: block;
  max-width: rem(217px);
  livelike-widget-header {
    background: none;
    color: $white;
    font-size: rem(12px);
    font-weight: 700;
  }
  livelike-widget-body {
    background: none;
  }
  livelike-footer {
    display: none;
  }

  livelike-select {
    padding: rem(12px);

    livelike-option {
      background: rgba(88, 88, 88, 0.1);

      border: 1px solid $border-grey;
      margin-bottom: rem(10px);
      padding: rem(6px);
      livelike-description {
        font-size: rem(12px);
        font-weight: 700;
      }
    }

    livelike-option[selected] {
      background-color: rgba(255, 255, 255, 0.1);
    }

    livelike-option[disabled] {
      border: 1px solid rgba(255, 255, 255, 0);
      background-color: rgba(255, 255, 255, 0.1);
      livelike-progress {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    livelike-option[correct] {
      border: none;
      background: $light-blue;
      livelike-progress {
        background: $light-blue;
      }
    }

    livelike-option[changed][selected] {
      background-color: rgba(255, 255, 255, 0.1);
      livelike-progress {
        border-color: unset;
        background: rgba(255, 255, 255, 0.3);
      }
    }

    livelike-option[incorrect] {
      border: none;
      background: #cf0a2c;
      livelike-progress {
        background: #cf0a2c;
      }
    }

    livelike-percentage {
      font-size: rem(12px);
    }
  }
}

.interact.mobile {
  z-index: -1;
  position: fixed;
  left: 0;
  height: rem(550px);
  width: 100%;
  livelike-widgets {
    padding: 0 rem(10px) 0 rem(10px);
    height: 100%;
  }
  livelike-text-poll,
  livelike-text-quiz,
  livelike-text-prediction,
  livelike-text-prediction-follow-up {
    max-width: unset;
    livelike-widget-header {
      font-size: rem(14px);
    }
    livelike-select {
      livelike-option {
        livelike-description {
          font-size: rem(14px);
        }
      }
    }
  }
}
