@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/media-queries.scss";

.schedule-widget {
  width: rem(335px);
  border-radius: 10px;
  padding-top: rem(4px);

  &.open {
    background-color: $grey;
    max-height: rem(386px);
    + .sub-nav-visuallyHidden {
      left: rem(335px);
      position: absolute;
    }
  }

  .schedule-title {
    font-size: rem(15px);
    padding: 5%;
  }

  .widget-menu {
    @include nab-font(Roboto-Bold);
    position: relative;
    cursor: pointer;
    border-radius: rem(10px);
    background-color: $dark-blue;
    color: $white;
    width: rem(325px);
    height: rem(36px);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: rem(14px);
    font-weight: 700;
    margin: 0 auto 0 auto;
    > span {
      margin-left: rem(30px);
    }
  }
  .widget-tabs {
    li {
      cursor: pointer;
      display: inline-block;
      list-style: none;
      padding: rem(6px) rem(16px) rem(6px) rem(16px);
      &.active {
        background-color: $light-blue;
        border: 1px solid $white;
        border-radius: rem(6px);
      }
    }
  }

  .widget-content {
    padding: rem(4px) rem(12px) rem(12px) rem(12px);
    .wrapper {
      height: rem(320px);
      overflow-y: auto;
      color: $white;

      .date-filter {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(14px);
        font-weight: 700;
        color: $white;
        height: rem(24px);
        border-radius: rem(6px);
        background-color: $light-blue;
        position: relative;
      }

      .status-message {
        margin-top: rem(40px);
        font-weight: 700;
        text-align: center;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .chevron {
    position: absolute;
    right: 0px;
    height: rem(30px);
    width: (30px);
    cursor: pointer;
    &::before {
      border-style: solid;
      border-width: 0.2em 0.2em 0 0;
      content: "";
      display: inline-block;
      height: 0.55em;
      left: 0.15em;
      position: relative;
      top: 0.5em;
      transform: rotate(135deg);
      vertical-align: top;
      width: 0.55em;
    }
    &.up:before {
      top: 0.75em;
      transform: rotate(-45deg);
    }
    &.left {
      position: relative;
      transform: rotate(90deg);
      right: 8px;
      &::before {
        height: 0.4em;
        width: 0.4em;
        top: 0.8em;
        left: 0.85em;
      }
    }
    &.right {
      position: relative;
      transform: rotate(-90deg);
      left: rem(14px);
      &::before {
        height: 0.4em;
        width: 0.4em;
        top: 0.8;
        left: 0.8em;
      }
    }
  }
}
