@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";

.header-video {
  margin-bottom: rem(10px);
  margin-left: rem(7px);
  display: inline-block;
  justify-content: space-between;

  &--lone-widget {
    justify-content: flex-end;
  }
  @media ($maxw-bp-sm-desktop) {
    justify-content: space-between;
  }
  @media ($maxw-bp-large-mobile) {
    display: none;
  }
}