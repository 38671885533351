@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/media-queries.scss";

.home-live-score {
  display: flex;
  justify-content: space-between;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 50%;

  .scores {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 42%;
  }

  .grid-item {
    &.logo {
      display: flex;
      align-items: center;
      img {
        height: rem(60px);
        width: auto;
      }
      div {
        font-size: rem(20px);
        line-height: rem(26px);
        color: #fff;
        @include nab-font(Roboto-Bold);
        margin-left: rem(5px);
      }
    }
  }

  .time-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 3%;
    
    .quarter {
      font-size: rem(14px);
      line-height: rem(17px);
      color: #fff;
      @include nab-font(Roboto-Bold);
    }
    .remaining-time {
      font-size: rem(14px);
      line-height: rem(17px);
      color: #fff;
      @include nab-font(Roboto-Bold);
    }
  }
}

