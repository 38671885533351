@import "../../styles/mixins.scss";
@import "../../styles/media-queries.scss";
@import "../../styles/variables.scss";
livelike-chat {
  display: block;
  width: 100%;
  overflow-y: auto;
  justify-content: end;
  align-items: start;
  padding-left: rem(16px);
  @media only screen and (max-width: $bp-large-mobile) {
    padding-left: rem(8px);
  }
}
livelike-message-item {
  color: $white;
  font-size: rem(12px);
  font-weight: 400;
  .sender-name {
    font-size: rem(11px);
    font-weight: 700;
  }
  .sender-message {
    font-size: rem(12px);
    font-weight: 400;
  }
  .message-timestamp {
    font-size: rem(10px);
    font-weight: 500;
    color: #d3d3d3;
  }
  .message-item-container {
    width: max-content;
    margin-left: auto;
    max-width: rem(200px);
    padding: rem(8px) rem(20px) 0 rem(6px);
    justify-content: center;
    align-items: center;
    border-radius: rem(10px);
    background: rgba(0, 0, 0, 0.78);
    margin-bottom: rem(12px);

    .reaction-item-container {
      width: rem(200px);
    }

    .mini-reactions {
      top: rem(-22px);
      align-items: center;
      .mini-reaction-image {
        padding-right: rem(10px);
      }
    }
  }
  .message-wrapper {
    @include nab-font(Roboto-Regular);
    background: none;
    width: rem(116px);
    padding: rem(2px) rem(20px) rem(2px) rem(2px);
    .reaction-button {
      width: rem(14px);
      height: rem(14px);
    }
  }
  .avatar {
    svg,
    img {
      width: rem(30px);
      height: auto;
      margin-bottom: rem(40px);
    }
  }
}

livelike-scroll-down {
  .down-icon {
    right: 5.25rem;
  }
}

livelike-chat-composer {
  .livelike-chat-input {
    background-color: #1d2f44;
    color: white;
    font-size: rem(12px);
    max-width: rem(202px);
    height: rem(37px);
    &::placeholder {
      font-weight: 700;
      color: white;
    }
  }
  .send-icon {
    display: none;
  }
  .sticker-picker-container {
    right: rem(2px);
    .sticker-icon {
      svg {
        display: block;
        width: rem(14px);
        height: rem(14px);
      }
    }
  }
}

livelike-stickers {
  .sticker-container {
    max-width: rem(200px);
    .sticker-icon {
      svg {
        display: block;
        width: rem(14px);
        height: rem(14px);
      }
    }
  }
}

livelike-giphy-picker {
  display: none;
}

.chatroom.mobile {
  z-index: -1;
  position: fixed;
  left: 0;
  margin-top: rem(6px);
  height: rem(560px);
  bottom: rem(60px);
  width: 100%;

  livelike-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: auto;
    display: block;
    overflow-y: auto;
    justify-content: end;
    align-items: normal;
  }

  livelike-chat-message-avatar {
    margin-right: rem(8px);
  }

  livelike-chat-composer {
    position: fixed;
    bottom: 0;
    margin-bottom: 16px;
    .livelike-chat-input {
      max-width: unset;
      width: 92vw;
      @media only screen and (max-width: $bp-large-mobile) {
        width: 97vw;
      }
    }

    .sticker-picker-container {
      right: rem(13px);
    }
  }

  livelike-message-item {
    .message-item-container {
      background: #303030;
      margin-right: 4px;
      width: auto;
      max-width: unset;
      margin-top: rem(14px);
    }

    .message-wrapper {
      width: rem(246px);
    }
  }
  livelike-scroll-down .down-icon {
    right: 11rem;
    bottom: rem(10px);
  }
}

::-webkit-scrollbar {
  width: rem(10px);
}
::-webkit-scrollbar-track {
  background: transparent !important;
  width: rem(10px);
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: $border-grey;
}
